<template>
  <div>
    <h2 class="faledia-caption">Wieso ist zählendes Rechnen problematisch?</h2>
    <p>
      Das <i>verfestigte</i> zählende Rechnen gilt als ein Anzeichen von
      Problemen beim Rechnenlernen (PriMakom, o.J.) und ist zugleich einer der
      Hauptgründe für besondere Probleme beim Rechnenlernen (Gaidoschik &
      Fellmann, 2015). Beim zählenden Rechnen steht nicht der eigentliche
      Prozess des geschickten Rechnens im Vordergrund, vielmehr handelt es sich
      um einen Weg der zählenden Ermittlung von Lösungen gegebener Additions-
      und Subtraktionsaufgaben (PriMakom, o.J.). Zählende Rechenstrategien
      werden häufig durch Schüler:innen mit Schwierigkeiten im Fach Mathematik
      angewandt. Hierbei kommen besonders Fingerzähl-Strategien zum Einsatz
      (Moser Opitz, 2020). Auch in den höheren Jahrgangsstufen ist die
      Verwendung zählender Strategien durch Lernende mit niedrigeren
      Mathematikleistungen zu beobachten (Ostad, 1998; 2008; Schäfer, 2005), und
      auch noch bei Erwachsenen ist das zählende Rechnen vereinzelt anzutreffen
      (Wartha & Schulz, 2013).
    </p>
    <p>
      Gaidoschik (2010) beispielsweise unterscheidet zwischen den zählenden
      Vorgehensweisen “Alleszählen” (auch ‚counting all’) und “Weiterzählen”
      (auch ‚counting on‘). Beim Alleszählen werden alle drei Zahlen des
      Additions- bzw. Subtraktionsterms separat zählend ermittelt und
      dargestellt (Gaidoschik, 2010) – bei der Addition also erster und zweiter
      Summand sowie die Summe. Beim Weiterzählen wird bei der Addition vom
      ersten Summanden aus um den zweiten Summanden weitergezählt, um so das
      Ergebnis zu erhalten.
    </p>
    <AppExpandableImage
      contain
      :src="require('@/assets/nzr/AllesUndWeiterzaehlen.png')"
      parentId="nzr"
      elementId="nzr_allesundweiterzaehlen.png"
    >
    </AppExpandableImage>
    <br />
    <p>
      Die Zählfähigkeiten sagen bereits im Kindergartenalter die spätere
      Mathematikleistung als einer von mehreren Prädiktoren voraus (Freesemann,
      2014). Im weiteren Verlauf der mathematischen Bildung erwerben Lernende in
      der Regel die Fähigkeit, flexibel und in größeren Schritten zu zählen (für
      mehr Informationen zur Zählentwicklung:
      <a href="/zahlverstaendnis">Zahlverständnis</a>). Gelingt dieser Schritt
      jedoch nicht, bleiben die Schüler:innen oftmals beim Zählen in
      Einerschritten verhaftet (Moser Opitz, 2020). Dies macht deutlich, dass es
      wichtig ist, dem verfestigten zählenden Rechnen entgegenzuwirken, um
      dieser “Sackgasse” zu entgehen (Lorenz & Radatz, 1993).
    </p>
    <p>
      Carpenter und Moser (1984) haben in einer Längsschnittstudie die
      Zählstrategien in den Jahrgangsstufen 1-3 untersucht. Während zu Beginn
      des ersten Schuljahres alle Elemente von den meisten Kindern separat
      gezählt werden (‘counting all’), ist das Weiterzählen vom größeren der
      beiden Summanden im weiteren Verlauf der Studie bei fast allen Kindern
      eine beobachtbare Strategie (‘counting on’) (Gaidoschik, 2010). Auch das
      Auswendigwissen einzelner Aufgaben konnte als Strategie identifiziert
      werden, jedoch fällt besonders Lerner:innen mit mathematischen
      Lernschwierigkeiten diese Vorgehensweise schwer (Ostad, 1998). Zählende
      Vorgehensweisen führen meist dazu, dass die Lernenden Aufgaben rein
      isoliert voneinander betrachten und Zusammenhänge nicht identifizieren
      können (Häsel-Weide et al., 2017). Um Einsichten in mathematische Muster
      gewinnen zu können, ist es jedoch zentral, Erkenntnisse über Aufgaben
      hinweg in Form von Gemeinsamkeiten und Unterschieden zu gewinnen. Auch
      kann dadurch, dass das Zählen in Einerschritten im Regelfall auftritt, ein
      Verständnis für größere Einheiten nur schwer aufgebaut werden (Häsel-Weide
      et al., 2017).
    </p>
    <p>
      Besonders wichtig ist es zu Beginn der Grundschulzeit, dass die
      Schüler:innen die Vorstellung von Addition und Subtraktion auch kardinal
      denken, also als Operation mit Anzahlen. Dies gelingt jedoch nicht, wenn
      diese beiden Grundvorstellungen ausschließlich als sequenzielles Auszählen
      von Anzahlen bzw. als Vor- und Zurückzählen in der Zahlenfolge
      interpretiert werden. Eine solche einseitig ordinale Vorstellung beider
      Operationen gilt daher sowohl als Ursache als auch als Konsequenz
      zählenden Rechnens (Häsel-Weide, 2016; PriMakom, o.J.) und verhindert
      oftmals die Ausbildung eines mathematischen Verständnisses (Förderzentrum
      Mathematik-Team, o.J.; Freesemann, 2014).
    </p>
    <p>
      Vor allem in den höheren Zahlenräumen machen sich die Grenzen des
      zählenden Rechnens bemerkbar. Das Merken von Zwischenschritten oder das
      Weiterzählen ab bestimmten Zahlen ist mit einem steigenden Aufwand
      verbunden. Des Weiteren steigt die Fehleranfälligkeit, je größer der
      Zahlenraum ist.
    </p>
    <p>
      Im Folgenden sind Sie nun aufgefordert, einmal selbst auszuprobieren,
      Ergebnisse von Rechenaufgaben zählend zu ermitteln. Dabei sind die
      Aufgaben nicht wie für Sie gewohnt im dezimalen Stellenwertsystem, sondern
      in anderen Stellenwertsystemen dargestellt, um das zählende Rechnen von
      Kindern zu Lernbeginn möglichst authentisch erfahrbar zu machen, die sich
      noch nicht sicher im dezimalen Stellenwertsystem bewegen.
    </p>
    <p>
      Um sich nochmal zu vergegenwärtigen, wie in anderen Stellenwertsystemen
      weitergezählt wird, können Sie sich bei Bedarf vorab das folgende
      Erklärvideo zum Zählen im 4er-System anschauen.
    </p>
    <AppButtonCollapse
      messageHidden="Video anzeigen"
      messageNotHidden="Video verstecken"
    >
      <video
        class="lbst-border"
        preload="auto"
        controls
        width="60%"
        style="margin-left: 20%"
        @play="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @fullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @webkitfullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @mozfullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @msfullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @ended="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @pause="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @seeking="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @enterpictureinpicture="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @leavepictureinpicture="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
      >
        <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
        <source
          src="@/assets/swvi/4er-System um 1 weiterzählen_201103_YB.mp4"
          type="video/mp4"
        />
      </video>
    </AppButtonCollapse>
    <div style="background-color: white">
      <LernbausteinSchieberegler
        :id="Schieberegler.id"
        :workOrder="Schieberegler.workOrder"
        :originalSlideWidth="Schieberegler.originalSlideWidth"
        :originalSlideHeight="Schieberegler.originalSlideHeight"
        :slides="Schieberegler.slides"
      >
        <template v-slot:alternative-content>
          <p>
            Schauen Sie sich, wenn noch nicht getan, das Video zum Weiterzählen
            im 4er-System an. Bedenken Sie hier nun, dass beim Weiterzählen im
            7er-System jeweils sieben Elemente eines Stellenwerts zu einem
            Element im nächsthöheren Stellenwert gebündelt werden müssen – also
            gilt (7)<sub>7</sub> = (10)<sub>7</sub>. In der nachfolgenden
            Tabelle können Sie das Weiterzählen von (136)<sub>7</sub> um
            (23)<sub>7</sub> jeweils in Einerschritten nachvollziehen.
          </p>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>(136)<sub>7</sub></td>
                  <td>(140)<sub>7</sub></td>
                  <td>(141)<sub>7</sub></td>
                  <td>(142)<sub>7</sub></td>
                  <td>(143)<sub>7</sub></td>
                  <td>(144)<sub>7</sub></td>
                  <td>(145)<sub>7</sub></td>
                  <td>(146)<sub>7</sub></td>
                  <td>(150)<sub>7</sub></td>
                </tr>
                <tr>
                  <td></td>
                  <td>(1)<sub>7</sub></td>
                  <td>(2)<sub>7</sub></td>
                  <td>(3)<sub>7</sub></td>
                  <td>(4)<sub>7</sub></td>
                  <td>(5)<sub>7</sub></td>
                  <td>(6)<sub>7</sub></td>
                  <td>(10)<sub>7</sub></td>
                  <td>(11)<sub>7</sub></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>(151)<sub>7</sub></td>
                  <td>(152)<sub>7</sub></td>
                  <td>(153)<sub>7</sub></td>
                  <td>(154)<sub>7</sub></td>
                  <td>(155)<sub>7</sub></td>
                  <td>(156)<sub>7</sub></td>
                  <td>(160)<sub>7</sub></td>
                  <td>(161)<sub>7</sub></td>
                  <td>(162)<sub>7</sub></td>
                </tr>
                <tr>
                  <td>(12)<sub>7</sub></td>
                  <td>(13)<sub>7</sub></td>
                  <td>(14)<sub>7</sub></td>
                  <td>(15)<sub>7</sub></td>
                  <td>(16)<sub>7</sub></td>
                  <td>(20)<sub>7</sub></td>
                  <td>(21)<sub>7</sub></td>
                  <td>(22)<sub>7</sub></td>
                  <td>(23)<sub>7</sub></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinSchieberegler>
    </div>
    <p>
      Sicherlich ist Ihnen aufgefallen, dass es einige Mühe, vielleicht sogar
      Hilfsmittel erfordert, in einem ungewohnten Stellenwertsystem zählend ein
      Ergebnis zu ermitteln. Auch werden Sie ggf. mehrere Anläufe benötigt
      haben, um zum richtigen Ergebnis zu gelangen, denn das zählende Rechnen
      ist schnell fehleranfällig (Moser Opitz, 2013; 2020). Dennoch zeigen
      Untersuchungen von Moser Opitz (2020) und Schäfer (2005), dass durchaus
      auch ältere Schüler:innen mit Schwierigkeiten beim Rechnen Rechenaufgaben
      häufiger durch zählendes – oder auch schriftliches – Rechnen lösen als
      durch Abruf oder Ableitungs- und Zerlegungsstrategien.
    </p>
    <AppLiteraturNZR />

    <AppBottomNavNZR
      back="/nicht-zaehlendes-rechnen/hintergrundwissen"
      next="/nicht-zaehlendes-rechnen/hintergrundwissen/teil-ganzes-verstaendnis"
    />
  </div>
</template>

<script>
import AppBottomNavNZR from "@/common/AppBottomNavNZR";
import AppLiteraturNZR from "@/common/AppLiteraturNZR";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import LernbausteinSchieberegler from "@/components/faledia/explorativeElemente/LernbausteinSchieberegler";

import Schieberegler from "@/components/faledia/seiten/nichtZaehlendesRechnen/LBST/LBST_Schieberegler_AndereZahlsysteme";

export default {
  components: {
    AppBottomNavNZR,
    LernbausteinSchieberegler,
    AppExpandableImage,
    AppButtonCollapse,
    AppLiteraturNZR,
  },
  data: () => ({ Schieberegler: Schieberegler }),
};
</script>

<style scoped></style>
