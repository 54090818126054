function createObject() {
  let example = {
    id: "AndereZahlsysteme",
    workOrder:
      "<p>Folgend sind Zahlen in anderen Stellenwertsystemen dargestellt. Zählen Sie von der Ausgangszahl jeweils um die angegebene Zahl weiter.</p>",
    originalSlideWidth: 750,
    originalSlideHeight: 400,
    slides: [
      {
        image: require("@/assets/nzr/Schieberegler/Step0.png"),
      },
      {
        animation: require("@/assets/nzr/Schieberegler/Step0.webm"),
        animationAlt: require("@/assets/nzr/Schieberegler/Step0.mp4"),
        intervention: true,
        interventionText:
          "<p>Wie sähe eine Erhöhung um (23)<sub>7</sub> aus? Zählen Sie zunächst weiter und wählen Sie dann den korrekten Wert aus.</p>",
        interventionOptions: [
          {
            title: "A",
            correct: false,
            hintText:
              "Diese Lösung ist nicht korrekt. Hier wurde der Bündelungszwang (Bündelungszwang: Die maximale Ziffer pro Stelle ist jeweils um 1 kleiner als die Basis des Stellenwertsystems. Deswegen muss nach der 6 im 7er-System gebündelt werden.) nicht korrekt eingehalten, weshalb sich eine Zahl ergibt, die im 7er-System (Stellenwertsysteme: Anders als im Dezimalsystem muss im Siebenersystem nicht nach 9, sondern nach 6 bereits gebündelt werden. Die Basis des Stellenwertsystems (hier 7) oder höhere Ziffern können deswegen nicht Bestandteil des Zahlwortes sein.) nicht realisiert werden kann.",
            top: 65,
            left: 365,
            width: 140,
            height: 70,
          },
          {
            title: "B",
            correct: true,
            top: 150,
            left: 365,
            width: 140,
            height: 70,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "Diese Lösung ist nicht korrekt. Der gewählte Wert ist zu niedrig, was auf den Minus-Eins-Fehler zurückzuführen sein kann: Grundschüler/innen zählen beim Weiterzählen häufig die Ausgangszahl mit, weshalb das ermittelte Ergebnis zu gering ist.",
            top: 230,
            left: 365,
            width: 140,
            height: 70,
          },
        ],
      },
      {
        animation: require("@/assets/nzr/Schieberegler/Step1.mp4"),
        animationAlt: require("@/assets/nzr/Schieberegler/Step1.mp4"),
        successText:
          "<p>Prima! Das Weiterzählen erfordert, dass zunächst ein Stellenwertübergang (Stellenwertübergang: Die maximale Ziffer pro Stelle ist jeweils um 1 kleiner als die Basis des Stellenwertsystems. Deswegen muss nach der 6 im 7er-System die nächstgrößere Stelle um 1 erhöht werden.) gemacht werden muss, weshalb sich die 7<sup>1</sup>-Spalte (Stellenwertprinzip: Der Wert einer Ziffer ist abhängig von ihrer Position im Stellenwertsystem) um 1 erhöht. Weiterzählen um die verbleibenden (22)<sub>7</sub> führt schließlich zum korrekten Ergebnis.</p>",
        intervention: true,
        interventionText:
          "<p>Wie sähe eine Erhöhung um (12)<sub>3</sub> aus? Zählen Sie zunächst weiter und wählen Sie dann den korrekten Wert aus.</p>",
        interventionOptions: [
          {
            title: "A",
            correct: true,
            top: 70,
            left: 360,
            width: 270,
            height: 77,
          },
          {
            title: "B",
            correct: false,
            hintText:
              "<p>Hier wurden die Plättchen korrekt in die Spalten hinzugefügt und eine Bündelung (Bündelungsprinzip: In einem Stellenwertsystem zur Basis b werden immer je b Einheiten eines Stellenwertes zu einer Einheit des nächsthöheren Stellenwertes gebündelt) der Einer wurde vorgenommen. Allerdings überschreiten nur die Plättchen in der 7<sup>1</sup>-Spalte (Stellenwertprinzip: Der Wert einer Ziffer ist abhängig von ihrer Position im Stellenwertsystem) die maximal mögliche Anzahl. Der Bündelungszwang (Der Ziffernvorrat eines Stellenwertsystems zur Basis b beläuft sich auf die natürlichen Zahlen von 0 bis b-1. Zahlwörter wie (5)<sub>3</sub> oder (15)<sub>3</sub> sind ungültig, da Bündelungszwang besteht; korrekt wären (12)<sub>3</sub> bzw. (120)<sub>3</sub>) wurde somit nicht vollständig eingehalten.</p>",
            top: 150,
            left: 360,
            width: 270,
            height: 77,
          },
          {
            title: "C",
            correct: false,
            hintText:
              "<p>Hier wurden fünf Plättchen in die 7<sup>1</sup>-Spalte (Stellenwertprinzip: Der Wert einer Ziffer ist abhängig von ihrer Position im Stellenwertsystem) gelegt und damit fünf Einer (Multiplikatives Prinzip: Jede Ziffer einer Zahl gibt die Anzahl der Bündelungseinheiten eines Stellenwertes an. Ihr Zahlenwert ergibt sich aus der Multiplikation von Ziffer und Stellenwert) hinzugefügt. Es wurde jedoch nicht korrekt gebündelt (Bündelungsprinzip: In einem Stellenwertsystem zur Basis b werden immer je b Einheiten eines Stellenwertes zu einer Einheit des nächsthöheren Stellenwertes gebündelt). Da die Anzahl der Plättchen in einer Spalte größer ist als die Basis, ist der in der Aufgabe geforderte Bündelungszwang (Der Ziffernvorrat eines Stellenwertsystems zur Basis b beläuft sich auf die natürlichen Zahlen von 0 bis b-1. Zahlwörter wie (5)<sub>3</sub> oder (15)<sub>3</sub> sind ungültig, da Bündelungszwang besteht; korrekt wären (12)<sub>5</sub> bzw. (120)<sub>5</sub> nicht erfüllt.</p>",
            top: 240,
            left: 360,
            width: 270,
            height: 77,
          },
        ],
      },
      {
        image: require("@/assets/nzr/Schieberegler/Step2.png"),
        successText:
          "<p>Richtig! Hier wurden die zwei Einer und der Dreier korrekt in die entsprechenden Spalten hinzugefügt. Die Plättchen wurden anschließend korrekt zum nächsten Stellenwert gebündelt (Bündelungszwang: Die maximale Ziffer pro Stelle ist jeweils um 1 kleiner als die Basis des Stellenwertsystems. Deswegen muss nach der 6 im 7er-System gebündelt werden). Die Zahl (212)<sub>3</sub> wurde also um (12)<sub>3</sub>  zu (1001)<sub>3</sub>  erhöht.</p>",
      },
    ],
  };

  return example;
}

export default createObject();
